<template>
  <a-card class="card" :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="GLOBAL.queryRowGutter">
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="订单编号">
              <a-input v-model="queryParam.serial_num" placeholder="根据订单编号进行查询" />
            </a-form-item>
          </a-col>

          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="供应商">
              <a-select
                showSearch
                allowClear
                :filter-option="filterOption"
                placeholder="选择供应商"
                v-model="queryParam.supplier"
              >
                <a-select-option v-for="op in supplierOps" :key="op.value">{{ op.name }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="创建时间">
              <a-range-picker @change="onDateChange"/>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </div>

    <div class="table-operator">
      <a-button type="query" icon="search" @click="$refs.table.refresh(true)">查询</a-button>
    </div>

    <s-table
      ref="table"
      size="default"
      rowKey="id"
      :scroll="{ x: 'max-content' }"
      :columns="columns"
      :data="loadData"
    >
      <span slot="serial" slot-scope="text, record, index">{{ index + 1 }}</span>
      <div slot="feeStatusItem" slot-scope="text, record">
        <a-tag :color="feeStatusColor(record.fee_status)" style="margin-bottom:2px;">
          {{ GLOBAL.feeStatusMaps[record.fee_status] ? GLOBAL.feeStatusMaps[record.fee_status].name : '未提交' }}
        </a-tag>
      </div>
      <span slot="action" slot-scope="text, record">
        <template>
          <a @click="printPayBillSheet(record)" style="margin-right:10px;">付款单</a>
          <a v-if="record.is_advance === 2 && record.flag !== 1" @click="handleBindInvoice(record)">关联发票</a>
        </template>
      </span>
      <div slot="bankInfo" slot-scope="record">
        <span>
          付款银行：<span style="color: green;">{{ record.bank_name }}</span>
          银行账号：<span style="color: green;">{{ record.account }}</span>
        </span>
      </div>
      <div slot="invoiceInfoList" slot-scope="text">
        <span style="display:block" v-for="item in text" :key="item.id">{{ item.invoice_number }} 金额：{{ item.amount }} 税额：{{ item.tax }}</span>
      </div>
    </s-table>

    <!-- 付款通知单 -->
    <a-modal
      :visible="printPayBillModel"
      :confirmLoading="printPayBillModelLoading"
      :width="1520"
      :maskClosable="false"
      title="打印付款单"
      @cancel="printPayBillModel = false"
    >
      <div id="printPayBill">
        <fms-pay-bill
          :orderList="printPayBillResult"
          :orderData="printPayBillRecord">
        </fms-pay-bill>
      </div>

      <template slot="footer">
        <a-button
          id="printPayBillBtn"
          key="submit"
          type="primary"
          v-print="printPayBill">确认打印</a-button>
        <a-button type="primary" @click="printPayBillModel = false">关闭</a-button>
      </template>
    </a-modal>

    <a-modal
      :visible="invoiceModel"
      :confirmLoading="invoiceModelLoading"
      :width="720"
      :maskClosable="false"
      title="关联发票"
      @cancel="invoiceModel = false"
      @ok="handleBindInvoiceSubmit"
    >
      <bind-invoice ref="bindInvoice" />
    </a-modal>
  </a-card>
</template>

<script>
import { STable } from '@/components';
import { getSupplier } from '@/api/common'
import { getWmsPaymentPage } from '@/api/wms'
import { getFmsInfoDetail, fmsBizAuditFunc } from '@/api/fms'
import FmsPayBill from '@/views/fms/bill/FmsPayBill.vue';
import bindInvoice from '@/views/components/BindInvoice'
import moment from 'moment';
import { getFirstLetter } from '@/utils/util'

export default {
  name: 'WmsPayment',
  components: {
    STable,
    FmsPayBill,
    bindInvoice
  },
  data() {
    return {
      queryParam: {},
      // 供应商
      supplierOps: [],
      /// 付款方式
      paymentMap: {
        1: { value: 1, name: '转账' },
        2: { value: 2, name: '支票' },
        3: { value: 3, name: '代付' },
        4: { value: 4, name: '打卡' },
        5: { value: 5, name: '现金' },
        6: { value: 6, name: '内部结算' }
      },
      columns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '订单号',
          dataIndex: 'serial_num'
        },
        {
          title: '开票状态',
          dataIndex: 'fee_status',
          scopedSlots: { customRender: 'feeStatusItem' }
        },
        {
          title: '供应商名称',
          dataIndex: 'supplier_name'
        },
        {
          title: '是否预付',
          dataIndex: 'is_advance',
          customRender: (record) => {
            return record === 1 ? '非预付' : '预付';
          }
        },
        {
          title: '付款方式',
          dataIndex: 'pay_method',
          customRender: text => {
            return this.paymentMap[text] ? this.paymentMap[text].name : '';
          }
        },
        {
          title: '申请备注',
          dataIndex: 'apply_remark'
        },
        {
          title: '发票信息',
          dataIndex: 'invoice_info_list',
          scopedSlots: { customRender: 'invoiceInfoList' }
        },
        {
          title: '付款信息',
          dataIndex: 'bank',
          customRender: text => {
            return text !== null ? text : {}
          },
          scopedSlots: { customRender: 'bankInfo' }
        },
        {
          title: '创建时间',
          dataIndex: 'created_at',
          customRender: (text) => {
            return text && moment(text).format('YYYY-MM-DD');
          }
        },
        {
          title: '操作',
          fixed: 'right',
          scopedSlots: { customRender: 'action' }
        }
      ],
      loadData: parameter => {
        return getWmsPaymentPage(Object.assign(parameter, this.queryParam)).then(res => {
          return res;
        });
      },
      printPayBillModel: false,
      printPayBillModelLoading: false,
      printPayBill: {
        id: 'printPayBill',
        popTitle: '',
        extraCss: '',
        extraHead: '<meta http-equiv="Content-Language"content="zh-cn"/>'
      },
      printPayBillResult: [],
      printPayBillRecord: {},
      auditId: null,
      invoiceModel: false,
      invoiceModelLoading: false
    }
  },
  created() {
    getSupplier({ department: 1 }).then(res => {
      this.supplierOps = res;
    });
  },
  methods: {
    handleBindInvoice(record) {
      this.auditId = record.audit_id
      this.invoiceModel = true
    },
    handleBindInvoiceSubmit() {
      this.$confirm({
        title: '确认提示',
        content: '确定要关联发票？',
        onOk: () => {
          const data = {
            audit_id: this.auditId,
            invoice_list: this.$refs.bindInvoice.invoice_num
          }
          fmsBizAuditFunc('bind_invoice', data).then((v) => {
            if (v) {
              this.$message.success('关联发票成功')
              this.invoiceModel = false
              this.$refs.table.refresh(false);
              this.$refs.bindInvoice.invoice_num = []
              this.$refs.bindInvoice.initData()
            }
          })
        }
      })
    },
    feeStatusColor(value) {
      switch (value) {
        case 1:
          return '#faad14'
        case 2:
          return '#1890ff'
        case 3:
          return '#4cd964'
        case 4:
          return '#ff4d4f'
        default:
          return '#4cd964'
      }
    },
    // 打印付款单
    printPayBillSheet(record) {
      if (typeof record.audit_id !== 'undefined') {
        getFmsInfoDetail('business_audit', { id: record.audit_id }).then(v => {
          const result = [];
          v.forEach(item => {
            const feeList = item.pay_fee_list;
            if (typeof feeList !== 'undefined') {
              feeList.forEach(fee => {
                result.push({
                  order_num: fee.order_num,
                  product_name: fee.product_name,
                  fee_name: fee.fee_name,
                  amount: fee.amount
                });
              });
            }
          });
          this.printPayBillResult = result;
          this.printPayBillRecord = record;
          this.printPayBillRecord['customer'] = record.supplier_name;
          this.printPayBillRecord['remark'] = record.apply_remark;
          this.printPayBillRecord['department'] = 1;
          this.printPayBillRecord[`currency_name`] = `CNY`;
          this.printPayBillRecord['contract_list'] = record.lease_info_list
          this.printPayBillRecord['invoice_count'] = record.invoice_info_list ? record.invoice_info_list.length : 0
          this.printPayBillModel = true;
        });
      } else {
        this.$message.warning(`当前付款单没有提交商务审核，暂时无法查看付款通知单~`);
      }
    },
    onDateChange(date, dateString) {
      if (date[0]) {
        this.queryParam['create_start_date'] = date[0].startOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.queryParam['create_end_date'] = date[1].endOf('day').format('YYYY-MM-DD HH:mm:ss')
      } else {
        this.queryParam['create_start_date'] = null
        this.queryParam['create_end_date'] = null
      }
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
        getFirstLetter(option.componentOptions.children[0].text)
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    }
  }
}
</script>

<style scoped>

</style>
